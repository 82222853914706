<template>
  <div class="page-competition-add">
    <pro-form
      label-width="120px"
      label-suffix=":"
      :model="model"
      :fields="fields"
      :rules="rules"
      :submitter="{
        submitButtonProps: {
          loading
        },
        submitText: '保存',
        resetText: '返回'
      }"
      @submit="submit"
      @reset="reset"
    ></pro-form>
  </div>
</template>

<script>
import { uid } from 'uid'
import Upload from '@/components/upload'
import { localStore } from '@/utils/store'
import * as ConstantRouter from '@/constant/router'

export default {
  data() {
    return {
      loading: false,
      id: this.$route.params.id,
      model: {
        name: '',
        nameEn: '',
        bannerImgFile: null,
        videoFile: null,
        stopSignTime: null,
        stopContributionTime: null,
        scoreRule: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: '竞赛名称(中文)',
            prop: 'name'
          },
          style: {
            width: '360px'
          }
        },
        {
          type: 'input',
          formItemProps: {
            label: '竞赛名称(英文)',
            prop: 'nameEn'
          },
          style: {
            width: '360px'
          }
        },
        {
          formItemProps: {
            label: '大赛顶部图',
            prop: 'bannerImgFile'
          },
          render(h, prop, field, model) {
            return (
              <Upload
                v-model={model[prop]}
                token={localStore.get('dashboard-token')}
              ></Upload>
            )
          }
        },
        {
          formItemProps: {
            label: '视频',
            prop: 'videoFile'
          },
          render(h, prop, field, model) {
            return (
              <Upload
                type="video"
                v-model={model[prop]}
                token={localStore.get('dashboard-token')}
              ></Upload>
            )
          }
        },
        {
          type: 'date-picker',
          formItemProps: {
            label: '投稿截止时间',
            prop: 'stopContributionTime'
          },
          attributes: {
            valueFormat: 'yyyy-MM-dd'
          },
          style: {
            width: '360px'
          }
        },
        {
          type: 'textarea',
          formItemProps: {
            label: '评分规则',
            prop: 'scoreRule'
          },
          attributes: {
            autosize: {
              minRows: 4
            }
          },
          style: {
            width: '360px'
          }
        }
      ],
      rules: {
        name: [
          { required: true, message: '请输入竞赛标题(中文)', trigger: 'change' }
        ],
        nameEn: [
          { required: true, message: '请输入竞赛标题(英文)', trigger: 'change' }
        ]
      }
    }
  },
  mounted() {
    if (this.id) this.fetchDetail()
  },
  methods: {
    async fetchDetail() {
      this.loading = true
      const [err, res] = await this.$serve.jsRecord.info({
        queryId: this.id
      })
      this.loading = false
      if (err) return
      this.model = {
        name: res.name,
        nameEn: res.nameEn,
        bannerImgFile: res.bannerImg
          ? {
              uid: uid(),
              status: 'success',
              url: res.bannerImg
            }
          : null,
        videoFile: res.videoUrl
          ? {
              uid: uid(),
              status: 'success',
              url: res.videoUrl
            }
          : null,
        stopSignTime: res.stopSignTime,
        stopContributionTime: res.stopContributionTime,
        scoreRule: res.scoreRule
      }
    },
    async submit({ bannerImgFile, videoFile, ...data }) {
      const params = {
        ...data
      }
      if (bannerImgFile) {
        params.bannerImg = bannerImgFile.response?.data || bannerImgFile?.url
      }
      if (videoFile) {
        params.videoUrl = videoFile.response?.data || videoFile?.url
      }
      if (this.id) {
        this.update(params)
      } else {
        this.add(params)
      }
    },
    reset() {
      this.$router.push({
        name: ConstantRouter.DASHBOARD_COMPETITION
      })
    },
    async add(data) {
      this.loading = true
      const [err] = await this.$serve.jsRecord.add({
        data
      })
      this.loading = false
      if (err) return
      this.$message.success('竞赛添加成功')
      this.$router.push({
        name: ConstantRouter.DASHBOARD_COMPETITION
      })
    },
    async update(data) {
      this.loading = true
      const [err] = await this.$serve.jsRecord.update({
        data: {
          ...data,
          id: this.id
        }
      })
      this.loading = false
      if (err) return
      this.$message.success('竞赛修改成功')
      this.$router.push({
        name: ConstantRouter.DASHBOARD_COMPETITION
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
