var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-competition-add"},[_c('pro-form',{attrs:{"label-width":"120px","label-suffix":":","model":_vm.model,"fields":_vm.fields,"rules":_vm.rules,"submitter":{
      submitButtonProps: {
        loading: _vm.loading
      },
      submitText: '保存',
      resetText: '返回'
    }},on:{"submit":_vm.submit,"reset":_vm.reset}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }